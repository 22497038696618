<template>
  <div class>
    <small>
      <div class="d-flex align-items-center" :class="{ 'text-muted': mode == 1 ? true : false }">
        <div class="d-flex" v-if="mode == 2">
          <base-icon class="mr-1" name="file-text" width="14" height="14"></base-icon>
        </div>
        {{ contratSelection }}
      </div>
    </small>
  </div>
</template>
<script>
import date from "@/mixins/date";
import BaseIcon from "@/components/bases/Icon.vue";
export default {
  components: { BaseIcon },
  mixins: [date],
  props: {
    contrat: {
      Type: Object,
      Requierd: true,
      Default: {},
    },
    mode: {
      Type: Number,
      Requierd: false,
      Default: 1,
    },
  },
  computed: {
    contratSelection() {
      
      // VAR
      let contratVersions = [];
      let contratSelected = {};
      let versionSelected = {};

      // -----------------------------------------------------------------------------------
      // ANALYSE DES CONTRATS
      // -----------------------------------------------------------------------------------

      // 1 SEUL CONTRAT
      if (this.contrat.length == 1) {
        if (this.contrat[this.contrat.length - 1].contrat_version_all) {
          contratVersions = this.contrat[this.contrat.length - 1].contrat_version_all;
          contratSelected = this.contrat[this.contrat.length - 1];
        }

        if (this.contrat[this.contrat.length - 1].contrat_version) {
          contratVersions = this.contrat[this.contrat.length - 1].contrat_version;
          contratSelected = this.contrat[this.contrat.length - 1];
        }
      }

      // PLUSIEURS CONTRATS
      if (this.contrat.length > 1) {
        let max_dtObj1 = this.$moment(this.contrat[0].contrat_date_debut);
        let indexContratMaxDate = 0;

        // Identifier l'index max sur la date de début
        this.contrat.forEach((dt, index) => {
          if (this.$moment(dt.contrat_date_debut) > max_dtObj1) {
            max_dtObj1 = this.$moment(dt.contrat_date_debut);
            indexContratMaxDate = index;
          }
        });

        if (this.contrat[indexContratMaxDate].contrat_version_all) {
          contratVersions = this.contrat[indexContratMaxDate].contrat_version_all;
          contratSelected = this.contrat[indexContratMaxDate];
        }

        if (this.contrat[indexContratMaxDate].contrat_version) {
          contratVersions = this.contrat[indexContratMaxDate].contrat_version;
          contratSelected = this.contrat[indexContratMaxDate];
        }
      }

      // AUCUN CONTRAT
      if (!contratVersions || contratVersions.length < 1) return "aucun contrat";

      // -----------------------------------------------------------------------------------
      // ANALYSE DES VERSIONS DU CONTRAT
      // -----------------------------------------------------------------------------------

      // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
      if (contratVersions.length >= 1) {
        let max_dtObj = this.$moment(contratVersions[0].date_effet);
        let indexVersionMaxDate = 0;

        // Identifier l'index max sur la date d'effet
        contratVersions.forEach((dt, index) => {
          if (this.$moment(dt.date_effet) > max_dtObj) {
            max_dtObj = this.$moment(dt.date_effet);
            indexVersionMaxDate = index;
          }
        });

        // Dernière version basée sur la date d'effet
        versionSelected = contratVersions[indexVersionMaxDate];
      }

      let str = ""
      let contratDateDebut = ""
      let contratDateFin = ""
      let contratSiret = ""
      let contratLibelle = ""
      let contratType = ""

      contratDateDebut = contratSelected.contrat_date_debut
      contratDateFin = contratSelected.contrat_date_fin
      contratSiret = versionSelected.contrat_siret.siret_societe.societe_nom_reduit
      contratLibelle = versionSelected.contrat_libelle
      contratType = versionSelected.contrat_type.description

      if (contratSiret) str = contratSiret + " ";
      if (contratType) str = str + contratType + " ";
      if (contratDateDebut && this.$moment(contratDateDebut) > this.$moment() && this.mode == 1) str = str + " - début le " + this.dateSimple(contratDateDebut);
      if (contratDateDebut && this.$moment(contratDateDebut) < this.$moment() && this.mode == 2) str = str + contratLibelle + " depuis le " + this.dateSimple(contratDateDebut);
      if (contratDateDebut && this.$moment(contratDateDebut) >= this.$moment() && this.mode == 2) str = str + contratLibelle + " prévu le " + this.dateSimple(contratDateDebut);
      if (contratDateFin && this.$moment(contratDateDebut) <= this.$moment()) str = str + " - fin le " + this.dateSimple(contratDateFin);
      return str
    },

    contratVersion() {
      // versions du contrat
      let contratVersions = [];

      // -----------------------------------------------------------------------------------
      // ANALYSE DES CONTRATS
      // -----------------------------------------------------------------------------------

      // 1 SEUL CONTRAT
      if (this.contrat.length == 1) {
        if (this.contrat[this.contrat.length - 1].contrat_version_all) {
          //contratVersions = this.contrat[indexMaxDate].contrat_version;
          contratVersions = this.contrat[this.contrat.length - 1]
            .contrat_version_all;
        }

        if (this.contrat[this.contrat.length - 1].contrat_version) {
          //contratVersions = this.contrat[indexMaxDate].contrat_version;
          contratVersions = this.contrat[this.contrat.length - 1]
            .contrat_version;
        }
      }

      // PLUSIEURS CONTRATS
      if (this.contrat.length > 1) {
        let max_dtObj1 = this.$moment(this.contrat[0].contrat_date_debut);
        let indexMaxDate = 0;

        this.contrat.forEach((dt, index) => {
          if (this.$moment(dt.contrat_date_debut) > max_dtObj1) {
            //max_dt = dt;
            max_dtObj1 = this.$moment(dt.contrat_date_debut);
            indexMaxDate = index;
          }
        });

        if (this.contrat[indexMaxDate].contrat_version_all) {
          //contratVersions = this.contrat[indexMaxDate].contrat_version;
          contratVersions = this.contrat[indexMaxDate].contrat_version_all;
        }

        if (this.contrat[indexMaxDate].contrat_version) {
          //contratVersions = this.contrat[indexMaxDate].contrat_version;
          contratVersions = this.contrat[indexMaxDate].contrat_version;
        }
      }

      // AUCUN CONTRAT
      if (!contratVersions || contratVersions.length < 1) return {};

      // -----------------------------------------------------------------------------------
      // ANALYSE DES VERSIONS DU CONTRAT
      // -----------------------------------------------------------------------------------

      // Filtrer les versions de contrat sur les dates d'effets antérieure à la date du jour
      const contratVersionsFiltered = contratVersions.filter(
        (v) => this.$moment(v.date_effet) <= this.$moment()
      );

      // get array length
      const nbrVersions = contratVersions.length;
      const nbrVersionsFiltered = contratVersionsFiltered.length;

      // Si une seule version : retour
      if (nbrVersions == 1 && nbrVersionsFiltered == 1)
        return contratVersions[0];

      // Identifier la dernière version si plusieurs versions d'un contrat (sur date d'effet)
      if (nbrVersions > 1 && nbrVersionsFiltered > 1) {
        let max_dtObj = this.$moment(contratVersionsFiltered[0].date_effet);
        let indexMaxDate = 0;

        contratVersionsFiltered.forEach((dt, index) => {
          if (this.$moment(dt.date_effet) > max_dtObj) {
            max_dtObj = this.$moment(dt.date_effet);
            indexMaxDate = index;
          }
        });
        return contratVersionsFiltered[indexMaxDate];
      }

      if (nbrVersions >= 1 && nbrVersionsFiltered === 0)
        return contratVersions[nbrVersions - 1];

      return {};
    },

    contratText() {
      let contratV = this.contratVersion;
      let contrat = this.contrat[0];
      let str = "";


      if (!this.contrat[0]) return "Aucun contrat";

      if (contratV.contrat_siret)
        str = contratV.contrat_siret.siret_societe.societe_nom_reduit + " ";
      if (contratV.contrat_type)
        str = str + contratV.contrat_type.description + " ";

      if (
        contrat.contrat_date_debut &&
        this.$moment(contrat.contrat_date_debut) > this.$moment() &&
        this.mode == 1
      )
        str =
          str + " - début le " + this.dateSimple(contrat.contrat_date_debut);

      if (
        contrat.contrat_date_debut &&
        this.$moment(contrat.contrat_date_debut) < this.$moment() &&
        this.mode == 2
      )
        str =
          str +
          contratV.contrat_libelle +
          " depuis le " +
          this.dateSimple(contrat.contrat_date_debut);

      if (
        contrat.contrat_date_debut &&
        this.$moment(contrat.contrat_date_debut) >= this.$moment() &&
        this.mode == 2
      )
        str =
          str +
          contratV.contrat_libelle +
          " prévu le " +
          this.dateSimple(contrat.contrat_date_debut);

      if (
        contrat.contrat_date_fin &&
        this.$moment(contrat.contrat_date_debut) <= this.$moment()
      )
        str = str + " - fin le " + this.dateSimple(contrat.contrat_date_fin);

      return str;
    },
  },
};
</script>
<style lang="css"></style>
